import React from 'react'
import ReactPlayer from 'react-player'
import ReactHlsPlayer from 'react-hls-player'

const FeedPage = () => {
    return (
        <div className="w-full h-full bg-[#02692b] flex items-center justify-center text-white flex-col">
            {/* <ReactPlayer url="http://localhost:4000/index.m3u8" playing={true} /> */}
            <ReactHlsPlayer
                src="https://49.149.92.229:1555/index.m3u8"
                autoPlay={true}
                controls={true}
                width="100%"
                height="auto"
            />
        </div>
    )
}

export default FeedPage